
.theme-toast {
    // @extend .primary-background;
    background-color: #000;
    color: #Fff;
    // @extend .primary-text-1;
    // @extend .primary-border;
}

.Toastify__toast-theme--light {
    background-color: #000 !important;
    color: #Fff !important;
}

.Toastify__close-button {
    color: #Fff !important;
}

.react-tel-input .form-control {
    // @extend .primary-background;
}

.pwa-update-screen {
    padding: 3rem;
    text-align: center;
}

body {
    padding: 0px !important;
    overflow: auto !important;
}