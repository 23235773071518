.send-button-container {
    background-color: #ebebeb;
    border-radius: 50px;
    padding: 0.5rem;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .send-button {
        background-color: #fff;
        border-radius: 50px;
        padding: 0.5rem;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}