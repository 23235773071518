.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #37B048;
    height: 100%; // Ensure the card takes up the full height

    .card-header {
        font-size: 1.25rem;
        font-weight: bold;
        color: #37B048;
        margin-bottom: 10px;
    }

    .card-content {
        font-size: 1rem;
        color: #555;
        margin-bottom: 20px;
        flex-grow: 1; // This will push the button to the bottom
        display: flex;
        flex-direction: column;
        justify-content: center; // Center the content vertically
    }

    .download-button {
        background-color: #37B048;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-top: auto; // This will ensure the button stays at the bottom

        &:hover {
            background-color: darken(#37B048, 10%);
        }

        .MuiSvgIcon-root {
            margin-right: 8px;
        }
    }
}

.sections-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.sections-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
}

.section {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Add this line */
    height: 100%;
    /* Ensure the section takes up the full height */

    .section-header {
        font-size: 1.2rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
    }

    .section-content {
        font-size: 1rem;
        color: #555;
        margin-bottom: 20px;
        flex-grow: 1;
        /* This will push the button to the bottom */
    }

    .revisit-button {
        background-color: #37B048;
        color: #fff;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-align: center;
        text-decoration: none;
        /* Remove underline */
        display: inline-block;
        /* Make it inline-block to behave like a button */
        margin-top: auto;
        /* This will ensure the button stays at the bottom */

        &:hover {
            background-color: darken(#37B048, 10%);
        }

        &:active {
            background-color: lighten(#37B048, 10%);
        }
    }
}