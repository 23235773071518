@import "src/assets/styles/abstracts/variables";

.navbar {
    padding: 1.25rem 1.8rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    background-color: #FAF9F6;

    .nav-items {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;


        @include respond(phone) {
            font-size: 25px;
            font-weight: 700;

        }
    }

    .nav-items:hover {
        font-size: 13px;
        color: rgb(244, 241, 241);
        cursor: pointer;
        background-color: #37B048;
        border-radius: 8px;
        padding: 0.5rem 1rem;
    }

    .nav-items-active {
        font-size: 13px;
        color: rgb(244, 241, 241);
        cursor: pointer;
        background-color: #37B048;
        border-radius: 8px;
        padding: 0.5rem 1rem;
        font-weight: 700;

        @include respond(phone) {
            font-size: 25px;
        }
    }

    .menu {
        right: -100%;
        transition: right 0.3s;
        display: none;

        @include respond(phone) {
            display: flex;
        }
    }

    .menu-open {
        right: 0
    }


}