@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }

    0% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }

    50% {
        box-shadow: 0 0 32px #007fb6, inset 0 0 14px #007fb6;
    }

    100% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }

    0% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }

    50% {
        box-shadow: 0 0 32px #007fb6, inset 0 0 14px #007fb6;
    }

    100% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

    0% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }

    50% {
        box-shadow: 0 0 32px #007fb6, inset 0 0 14px #007fb6;
    }

    100% {
        box-shadow: 0 0 16px #007fb6, inset 0 0 8px #007fb6;
    }
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(rgb(223, 223, 223), 0.65);
    z-index: 9999;

    .spinner-icon {
        display: inline-block;
        top: 50%;
        left: 50%;
        border: 4px solid #007fb6;
        border-radius: 50%;
        padding: 0.75rem;
        animation: spin 800ms ease infinite;
        -webkit-animation: spin 1s ease infinite;
        transform: translate(-50%, -50%);
        text-align: center;
        box-shadow: 1px 1px 1px 1px #007fb6;
    }
}