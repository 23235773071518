@import "/src/assets/styles/abstracts/variables";

.validation-msg {
    color: red !important;
    font-size: 12px;
    padding-left: 1rem;
}

.section-heading {
    font-size: 32px;
    @extend .primary-text-1;
    font-weight: 700;

    @include respond(phone) {
        font-size: 24px;

    }

    &.mob-heading {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        @extend .primary-text-1;

        .mob-back-btn {
            display: none;

            @include respond(phone) {
                display: block;
            }
        }
    }

}

.medium-heading {
    font-size: 21px;
    @extend .primary-text-1;
    font-weight: 700;

    @include respond(phone) {
        font-size: 14px;

    }

    &.mob-heading {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        @extend .primary-text-1;

        .mob-back-btn {
            display: none;

            @include respond(phone) {
                display: block;
            }
        }
    }

}

.section-lg-heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    @extend .primary-text-1;
}

.fs14 {
    font-size: 14px;
}

.fs13 {
    font-size: 13px;
}

.fs17 {
    font-size: 17px;
}

.fs18 {
    font-size: 18px;
}

.fs15 {
    font-size: 15px;
}

.fs11 {
    font-size: 11px;
}

.fs20 {
    font-size: 20px;

    @include respond(phone) {
        font-size: 18px;
    }
}


.fs32 {
    font-size: 32px;

    @include respond(phone) {
        font-size: 24px;
    }
}

.fs40 {
    font-size: 40px;

    @include respond(phone) {
        font-size: 30px;
    }
}

.fs42 {
    font-size: 42px;

    @include respond(phone) {
        font-size: 32px;
    }
}

.back {
    @extend .primary-background;
    cursor: pointer;

    @media screen and (min-width:769px) {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width:768px) {
        display: none;

    }
}

.bbt {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(10, 44, 63, 0.15);
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Choose File';
    display: inline-block;
    background: #1480B7;
    color: #fff;

    &:hover {
        filter: brightness(95%);
    }

    padding: 12px 14px;
    border-radius: 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
}

.section-container {
    background-color: #fff;
    padding: 1rem;
    border-radius: 13px;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.03);

    @include respond(phone) {
        border: none;
        box-shadow: none;
        border-radius: 0;
    }
}

.profile-img {
    border: 5px solid #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    width: 72px;
    height: 72px;
    border-radius: 50%;
}


.curve-section-container {
    border-radius: 112px;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06);
}



.otp-input-container {
    display: flex;
    gap: 0.25rem;

    div {
        width: 4rem;
        height: 4rem;
    }
}

.otp-input-box {
    border-radius: 50%;
    width: 56px !important;
    height: 56px !important;
    background-color: #ECF1F4;
    font-size: 25px;
}

.otp-input-box-gray {
    border-radius: 50%;
    width: 56px !important;
    height: 56px !important;
    background-color: #39424E;
    color: #fff;
    font-size: 25px;

}

.otp-input-box-green {
    border-radius: 50%;
    width: 56px !important;
    height: 56px !important;
    background-color: #3AB874;
    color: #fff;
    font-size: 25px;

}



.custom-position{
  position: relative;
  top:8rem !important;
  left:20rem !important
}
