$color-primary: #1480B7;
$color-secondary: #0A2C3F;
$color-background: #F3F6F7;

$color-primary-night: #1480B7;
$color-secondary-night: #ffffff;
$color-secondary-text-night: #cacaca;
$color-background-grey-night: #16151A;
$color-background-night: #000000;
$color-border-night: #848484;


// mixins
@mixin respond($breakpoint) {
    @if $breakpoint==phone-sm {
        @media only screen and (max-width: 36em) {
            @content;
        }
    }

    @if $breakpoint==phone {
        @media only screen and (max-width: 48.125em) {
            @content;
        }
    }

    @if $breakpoint==tab-port {
        @media only screen and (max-width: 62em) {
            @content;
        }
    }

    @if $breakpoint==tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        }
    }

    @if $breakpoint==big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content;
        }
    }
}

@mixin smallDesktop() {
	@media screen and (min-width:768px) and (max-width:1315px) {
		@content;
	}
}

@mixin circle($width, $height) {
    border-radius: 50%;
    height: $height;
    width: $width;
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dark {
    background-color: #16151A;

    .primary-text-75 {
        color: rgba(173, 173, 173, 0.75);
    }

    .primary-text-1 {
        color: #ffffff;
    }

    .primary-text-45 {
        color: rgba(173, 173, 173, 0.45);
    }

    .primary-background {
        // background-color: #16151A;
        background-color: #000000;

    }

    .secondary-background {
        // background-color: #000000;
        background-color: #16151A;
    }

    .primary-border {
        border: 1px solid rgba(59, 59, 59, 1);
    }

    .primary-border2 {
        border: 1px solid rgba(59, 59, 59, 1);
    }

}

.light {
    background-color: #ffffff;

    .primary-text-75 {
        color: rgba(10, 44, 63, 0.75);
    }

    .primary-text-1 {
        color: #0A0A0E
    }

    .primary-text-45 {
        color: rgba(10, 44, 63, 0.45);
    }

    .primary-background {
        background-color: #ffffff;
    }

    .secondary-background {
        background-color: #f7f7f7;
    }

    .primary-border {
        border: 1px solid rgba(17, 13, 63, .08);
    }
}