.login-container {
    .login-form {
        border-top-color: #0088cc;
        border-radius: 8px;
        border-top: 1px solid #CCC;
        border-top-width: 4px;
        padding: 30px 30px 50px 30px;
        position: relative;
        border: 1px solid #CCC;
        max-width: 450px;
    }
}